/* TAILWIND */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* UNIVERSAL */

@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&family=Muli:wght@400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;

  transition: all 0.2s ease;
}

body {
  background-color: #000;
  color: #fff;
  overflow-x: hidden;
  font-family: "Baloo Bhai 2", cursive;
}

/* NAVBAR */

@font-face {
  font-family: "Submariner_Bold";
  src: url("../../public/fonts/Submariner_Bold.ttf");
}

.nav-logo-heading {
  font-family: "Submariner_Bold";
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 100;
}

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #1c1917;
  --mainColorLight: #5767aa;
  --secondaryColor: #1ebaa3;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none !important;
  transition: 0.3s;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 2rem;
    max-height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
    max-width: 100vw;
    max-height: 100vw;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

.nav-logo-wrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
}

.nav-logo {
  height: 3rem;
}

.nav-logo-heading {
  font-weight: 700;
  font-size: 1.75rem;
  color: #1ebaa3;
}

/* HERO */

.hero {
  height: 90vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  color: #fff;
}
.vanta-canvas {
  width: 400rem;
}
.hero-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
  width: 100%;
  gap: 20%;
}

.hero-heading {
  font-size: 6.75em;
  text-align: center;
  width: fit-content;
  font-weight: 800;
}

.hero-subheading {
  font-size: 2rem;
  text-align: center;
  width: fit-content;
  color: #d9cdc4;
}

.hero-subheading-2 {
  font-size: 1.5rem;
}

.hero-text-subheading {
  text-align: center;
  font-size: 1.5rem;
  z-index: 1;
  transform: translateY(7.5rem);
}

@media screen and (max-width: 700px) {
  .hero-text-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .hero-heading {
    font-size: 3.25em;
    margin-left: 0;
    height: fit-content;
    text-align: center;
  }

  .hero-subheading {
    max-width: 70%;
    font-size: 1.5rem;
  }

  .hero-finetext {
    font-size: 20px;
    max-width: 252px;
    margin-right: 0;
    text-align: center;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.hero-finetext-header {
  font-size: 1.5em;
  font-weight: 700;
}

.hero-register-btn-wrapper {
  text-decoration: none !important;
  transform: translateY(40px);
}

.hero-register-btn {
  font-family: "Roboto", sans-serif;
  font-size: 1.25em;
  z-index: 1;
  background-color: #1ebaa3;
  border-radius: 5px 25px;
  padding: 20px 40px;
  display: block;
  margin: 0 auto;
  color: #000;
  font-weight: 500;
  line-height: 28pt;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: 3px 3px 1px 1px #95a4ff, 3px 3px 1px 2px rgba(0, 0, 0, 1);
  cursor: pointer;
}

.hero-register-btn:hover {
  background: rgba(0, 0, 0, 1);
  color: white;
  border: 1px solid rgba(0, 0, 0, 1);
}

.hero-register-btn::selection {
  background: #ffc8ff;
}

.hero-subtext {
  color: #fff;
  font-size: 1.5rem;
  z-index: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-wrapper {
  color: #d9cdc4;
}

.fade-1 {
  animation: fadeIn 2s;
}

.fade-2 {
  animation: fadeIn 4s;
}

.fade-3 {
  animation: fadeIn 6s;
}

.fade-4 {
  animation: fadeIn 8s;
}

@keyframes rotateWordsFirst {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    height: 0px;
  }

  8% {
    opacity: 1;
    height: 60px;
  }

  19% {
    opacity: 1;
    height: 60px;
  }

  25% {
    opacity: 0;
    height: 60px;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotateWordsSecond {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    width: 0px;
  }

  10% {
    opacity: 0.3;
    width: 0px;
  }

  20% {
    opacity: 1;
    width: 100%;
  }

  27% {
    opacity: 0;
    width: 100%;
  }

  100% {
    opacity: 0;
  }
}

.rw-wrapper {
  display: none;
  width: 80%;
  position: relative;
  font-family: "Bree Serif";
  padding: 10px;
}

.rw-sentence {
  margin: auto;
  text-align: center;
  width: fit-content;
  transform: translateX(-3rem);
}

.rw-sentence span {
  color: #fff;
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: normal;
}

.rw-words {
  display: inline;
  text-indent: 10px;
}

.rw-words span {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  width: fit-content;
  color: #d9cdc4;
}

.rw-words-1 span {
  animation: rotateWordsFirst 6s linear infinite 0s;
}

.rw-words span:nth-child(2) {
  animation-delay: 1.5s;
}

.rw-words span:nth-child(3) {
  animation-delay: 3s;
}

.rw-words span:nth-child(4) {
  animation-delay: 4.5s;
}

@media screen and (max-width: 700px) {
  .rw-wrapper {
    display: inline-block;
  }

  .hero-subtext {
    display: none;
  }
}

/* REGISTER AND CHECK WRAPPER */

.register-and-check-wrapper {
  display: flex;
  justify-content: space-evenly;
  color: #fff;
  padding: 150px 0;
  height: fit-content;
}

@media screen and (max-width: 700px) {
  .register-and-check-wrapper {
    flex-direction: column;
    width: 100%;
  }
}

/* REGISTRATION FORM */

#registration {
  scroll-behavior: smooth;
  padding-top: 10vh;
  padding-left: 1rem;
}

.registration-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
}

.registration-form-heading {
  padding: 0 auto;
  font-size: 30px;
}

.registration-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  padding: 15px 0;
}

.registration-form input,
.check-status-wrapper input {
  color: #fff;
  margin: 10px 0;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  font-family: sans-serif;
  margin-top: 30px;
  margin-bottom: 28px;
  border: none;
  border-bottom: 1px solid #57534e;
  background: rgba(0, 0, 0, 0.1);
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  transition: 0.3s;
}

.registration-form input:focus,
.check-status-wrapper input:focus {
  border-bottom: 2px solid #fff;
  color: #fff;
}

.registration-form input::-webkit-outer-spin-button,
.registration-form input::-webkit-inner-spin-button,
.check-status-wrapper input::-webkit-outer-spin-button,
.check-status-wrapper input::-webkit-inner-spin-button {
  display: none;
}

.registration-why-question {
  padding-top: 30px;
  font-size: 1.25em;
  margin-bottom: 30px;
}

.registration-why-answer {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  width: 100%;
  height: 8em;
  border-radius: 25px 5px;
  padding: 10px;
  border: 1px solid #57534e;
  transition: 0.3s;
}

.registration-why-answer:focus {
  border-color: #fff;
}

.registration-btn,
.check-status-btn {
  display: block;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-top: 10px;
  line-height: 14px * 2;
  padding: 10px 20px;
  background: #1ebaa3;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 25px 5px;
  box-shadow: 3px 3px 1px 1px #95a4ff, 3px 3px 1px 2px rgba(0, 0, 0, 1);
  cursor: pointer;
}

.registration-btn a {
  text-decoration: none;
}

.registration-btn:hover,
.check-status-btn:hover {
  background: #57534e;
  color: white;
  border: 1px solid rgba(0, 0, 0, 1);
}

.registration-btn::selection,
.check-status-btn::selection {
  background: #95a4ff;
}

.gpayqr {
  height: 50vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}
/* 
.qrbtn {
  margin-top: 1rem;
} */

/* CHECK STATUS FORM */

.check-status-container {
  padding: 10px;
}

.check-status-wrapper {
  height: fit-content;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}

.check-status-heading {
  font-size: 30px;
}

.check-status-wrapper input::placeholder {
  text-align: right;
}

.check-status-btn {
  border-radius: 5px 25px;
}

.check-status-btn-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.check-status-approval {
  font-size: 1.5rem;
}

.check-status-checklist-wrapper {
  display: flex;
  flex-direction: column;
  width: 20rem;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.check-status-checklist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-status-checklist-circle {
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
}

.check-status-checklist-circle.ok {
  background-color: #1ebaa3;
}

.check-status-checklist-circle.notok {
  background-color: grey;
}

@media screen and (max-width: 700px) {
  .check-status-wrapper {
    align-items: center;
    width: 100%;
    padding: 15px;
  }

  .check-status-checklist-circle {
    height: 20px;
    width: 20px;
  }

  .check-status-checklist-text {
    font-size: 1rem;
  }
}

/* COMMUNITY PARTNERS */
#community-partners {
  padding-top: 2vh;
}

.community-partners-heading {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
  margin-top: 6rem;
}

.community-partners-wrapper {
  margin: 2rem 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.community-partners-row {
  display: flex;
  justify-content: space-evenly;
}

.community-partners-element {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  overflow: hidden;
  transition: 0.4s;
  border: #000 solid 2px;
}

.community-partners-element:hover {
  transform: scale(1.1);
}

.grid-wrapper {
  display: flex;
  margin: 0 1rem;
  justify-content: center;
}

.grid-wrapper .grid {
  padding-bottom: 6rem;
}

/* FOOTER */

@import url("https://fonts.googleapis.com/css2?family=Inconsolata&family=Ubuntu+Mono&family=VT323&display=swap");

/* * {
  margin: 0;
} */

.links {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
  transform: translateX(-30px);
}

.footer-link {
  padding: 0.3rem;
}

@media screen and (max-width: 1024px) {
  .links {
    align-items: center;
  }
  .footer-links {
    align-items: center;
    transform: translateX(0);
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.footer-link-a:hover {
  color: #00ffd9;
}

.social-icons {
  display: flex;
}

.social-icons-list {
  display: inline-block;
  margin-bottom: 4px;
}

.social-icons-list-a {
  background-color: #393939;
  color: #bbbbbb;
  font-size: 1.3rem;
  display: inline-block;
  line-height: 44px;
  width: 3rem;
  height: 3rem;
  text-align: center;
  margin: 30px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/* .links.size-sm a {
  height: 34px;
  width: 34px;
  font-size: 14px;
} */

.links a.whatsapp:hover,
.links a.discord:hover,
.links a.twitter:hover,
.links a.linkedin:hover,
.links a.instagram:hover {
  background-color: #1ebaa3;
}

.footer {
  /* margin-top: 6em; */
  background-color: #202020;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.mail-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer-img {
  text-align: left;
}

.footer-img img {
  width: 17rem;
  padding: 0;
  margin: 20px;
}

.vertical-line {
  width: 1.5px;
  height: 150px;
  background-color: #393939;
}

.footer-img h2 {
  margin-top: -2rem;
  font-family: "VT323";
  margin: 5px;
  color: #1ebaa3;
  font-size: 1rem;
  margin-bottom: 10px;
  position: relative;
  left: 63px;
}

.footer-img a {
  text-decoration: none;
}

.footer-img a:hover {
  color: #1ea792;
}

@media screen and (max-width: 700px) {
  .links a {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    margin: 12px;
  }

  .footer-img {
    margin-bottom: 0;
  }

  .links {
    margin-bottom: 20px;
  }

  .vertical-line {
    display: none;
  }

  .footer-img h2 {
    text-align: center;
    position: static;
    left: 0;
  }
}

/* AWARDS SECTION */

.awards-wrapper {
  margin: auto;
  width: 70%;
}

.awards-heading {
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  padding: 6rem 0;
}

.awards-cards-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  gap: 16rem;
  padding: 2rem 0;
}

.awards-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.awards-card-logo {
  width: 18rem;
  filter: invert();
}

.awards-card-text {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  max-width: 16rem;
}

.awards-card-hero-text {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  padding: 3rem 0;
}

.awards-card-hero-text span {
  color: #1ebaa3;
  font-size: 4rem;
}

.awards-card-hero-text span span {
  font-size: 5rem;
}

@media screen and (max-width: 700px) {
  .awards-heading {
    padding: 3rem 0;
    font-size: 3rem;
  }

  .awards-cards-wrapper {
    flex-direction: column;
    gap: 4rem;
    padding-bottom: 0;
  }

  .awards-card-logo {
    width: 8rem;
  }

  .awards-card-hero-text {
    font-size: 1.5rem;
    padding-top: 2rem;
  }

  .awards-card-hero-text span {
    font-size: 1.5rem;
  }

  .awards-card-hero-text span span {
    font-size: 2.5rem;
  }
}

/* ROADMAP */

.roadmap-wrapper {
  padding: 5rem;
}

.roadmap-heading {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 2rem;
}

.roadmap-wrapper-row-one {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

@media (max-width: 700px) {
  .roadmap-wrapper-row-one {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.cards {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 0 0 18%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 20px;
}

.card-step {
  font-size: 2.25rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.card-img {
  max-height: 55%;
  max-width: 55%;
  object-fit: contain;
  filter: invert();
}

.card-back-img {
  max-height: 20%;
  max-width: 20%;
  object-fit: contain;
}

.card-title {
  width: 80%;
  text-align: center;
  color: #1ebaa3;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 30px;
  font-size: 1.75rem;
}

.card-description {
  padding: 20px;
  font-size: 1.5rem;
  font-weight: 600;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.5);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  border-radius: 8%;
}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flip-card-front {
  background: #000;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1ebaa3;
}

.flip-card-back {
  background-color: #000;
  color: #1ebaa3;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #032d60;
}

.swal-modal {
  background-color: teal;
}

.swal-text {
  color: black;
}

.swal-button {
  background-color: #1ea792;
}

/* faq section css  */

.freq-wrapper {
  background-color: #000;
  padding: 3rem;
}

.faq-row-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  font-family: "Sofia Sans";
  border-bottom: none !important;
}

.faq-body {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
}

.faq-row {
  flex: 1 0 50%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
}

.row-content-text {
  width: 80%;
}

.row-content-text a {
  color: #00ffd9 !important;
}

.row-title {
  display: flex;
  align-items: center;
}

.styles_icon-wrapper__2cftw.icon-wrapper {
  transform: translateX(-30px) rotate(0deg) !important;
  transition: 0.3s !important;
  font-size: 1.5rem;
  top: 5px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY.styles_expanded__3elPy
  .styles_icon-wrapper__2cftw {
  transform: translateX(-30px) rotate(180deg) !important;
  transition: 0.3s !important;
  font-size: 1.5rem !important;
  top: 25px !important;
}

.faq-title h2 {
  font-family: "Sofia Sans", sans-serif;
  font-weight: 700;
  padding-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .faq-body {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-title__1YiiY.styles_expanded__3elPy
    .styles_icon-wrapper__2cftw {
    transform: translateX(0) rotate(180deg) !important;
    transition: 0.3s !important;
  }
  .styles_icon-wrapper__2cftw.icon-wrapper {
    transform: translateX(0) rotate(0deg) !important;
  }
  .row-content-text {
    width: 100%;
  }
}

.swal-icon img {
  margin-left: auto;
  margin-right: auto;
  padding: 17px;
  border-radius: 5px;
  /* background: white; */
  /* box-shadow: 0 2px 10px rgb(192, 192, 192);   */
}
.swal-icon--success__hide-corners {
  background-color: transparent !important;
}
.swal-icon--success:after,
.swal-icon--success:before {
  background-color: transparent !important;
}
.swal-content div a {
  background-color: rgb(25, 25, 25);
  border-radius: 5px;
  padding: 3px 10px;
}
.Popup-Message {
  width: fit-content;
  height: fit-content;
  position: absolute;
}

.Payment-Modal {
  background-color: #000;
  color: #1ebaa3;
  width: fit-content;
  height: fit-content;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 10px;
}

.Payment-Modal img {
  height: 50vh;
}

.payment-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.payment-modal-wrapper input {
  display: none;
}

.payment-modal-btn {
  background-color: #000;
  border: 1px solid #fff;
  padding: 0.75rem 1.25rem;
  border-radius: 10px;
  color: #fff;
}

.crossbtn {
  border-radius: 100%;
  border: 1px solid #fff;
  padding: 0.5rem;
  color: #fff;
  margin-left: auto;
}

.PopupContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 2024;
  background-color: rgba(59, 59, 59, 0.253);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* COMMUNITY PARTNERS CAROUSEL */

/* .carousel-container {
  overflow: hidden;
  padding: 0 20px;
}
.carousel-flexbox {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: 100%;
  will-change: scroll-position;
  animation: slideLeftCarousel 5s linear infinite;
}

.carousel-flexbox img {
  width: 15%;
  height: 15%;
}

@keyframes slideLeftCarousel {
  0% {
    transform: translateX(-1.4%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.marquee {
  margin: 2rem 0;
  overflow-x: hidden;
  overflow-y: visible;
  padding: 10px 0;
}

.marquee::-webkit-scrollbar {
  display: none;
}

.community-marquee {
  display: flex;
}

.sellular-marquee-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.sellular-marquee-logo {
  width: 60vw;
}

.sellular-marquee-logo-text {
  font-family: "Submariner_Bold";
  font-size: 2rem;
  color: #1ebaa3;
}

.marquee-1-heading,
.marquee-3-heading {
  color: #fff;
  font-size: 1.75rem;
  position: relative;
  font-family: "Roboto";
  margin-left: 2vw;
  margin-bottom: 1vw;

  font-weight: 500;
}

.marquee-content-1 {
  display: flex;
  animation: scrolling1 50s linear infinite;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0.05rem;
}

.marquee-content-2 {
  display: flex;
  animation: scrolling2 50s linear infinite;
  padding: 0.05rem;
}

.marquee-content-3 {
  display: flex;
  animation: scrolling3 20s linear infinite;
  padding: 0.05rem;
}

.marquee-content-1:hover,
.marquee-content-2:hover,
.marquee-content-3:hover {
  animation-play-state: paused;
}

.marquee-item {
  flex: 0 0 12vw;
  margin: 0 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marquee-item-2 {
  background-color: rgba(45, 45, 45, 0.6);
  border-radius: 10px;
  padding: 2rem;
}

.marquee-item:hover {
  transform: scale(1.1);
}

.marquee-item img {
  display: block;
  width: 100%;
  border-radius: 10px;
}

@keyframes scrolling1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-406vw);
  }
}

@keyframes scrolling2 {
  0% {
    transform: translateX(-406vw); /* add 14 */
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scrolling3 {
  0% {
    transform: translateX(-210vw);
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 1024px) {
  .marquee-1-heading,
  .marquee-3-heading {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .marquee-item {
    flex: 0 0 48vw;
    margin: 0 2vw;
  }

  .marquee-content-1 {
    animation: scrolling1 43s linear infinite;
  }

  .marquee-content-2 {
    animation: scrolling2 59s linear infinite;
  }

  .marquee-content-3 {
    animation: scrolling3 14s linear infinite;
  }

  .sellular-marquee-logo-wrapper {
    width: 30vw;
    margin: 0rem 1rem;
  }

  @keyframes scrolling1 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1456vw);
    }
  }

  @keyframes scrolling2 {
    0% {
      transform: translateX(-1510vw); /* add 52 */
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes scrolling3 {
    0% {
      transform: translateX(-780vw);
    }
    100% {
      transform: translateX(0);
    }
  }
}

/* Team Registration */
.Register-Team-Form-headings {
  font-size: 1rem;
  font-weight: 600;
  color: #1ebaa3;
  font-family: "Sofia Sans", sans-serif;
}
.Register-Team-Form-Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.Register-Team-Form {
  padding: 0 15px 10px 15px;
  display: flex;
  gap: 2rem;
  width: 50vw;
  flex-direction: column;
  align-items: center;
}
.Register-Team-Form-heading {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  font-family: "Sofia Sans", sans-serif;
}
.Register-Team-Form-Container {
  width: 100%;
}
.Register-Team-Form-Container input {
  color: #fff;
  margin: 10px 0;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  font-family: sans-serif;
  margin-top: 30px;
  margin-bottom: 28px;
  border: none;
  border-bottom: 1px solid #57534e;
  background: rgba(0, 0, 0, 0.1);
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  transition: 0.3s;
}

.Register-Team-Form-Label {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1ebaa3;
  font-family: "Sofia Sans", sans-serif;
}
.Register-Team-Form-LeaderDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: fit-content;
}
.Register-Team-Form-LeaderDetails input {
  width: 80%;
  color: #fff;
  margin: 10px 0;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  font-family: sans-serif;
  margin-top: 30px;
  margin-bottom: 28px;
  border: none;
  border-bottom: 1px solid #57534e;
  background: rgba(0, 0, 0, 0.1);
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  transition: 0.3s;
}
.Register-Team-Form-MemberDetails input {
  color: #fff;
  margin: 10px 0;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  font-family: sans-serif;
  margin-top: 30px;
  margin-bottom: 28px;
  border: none;
  border-bottom: 1px solid #57534e;
  background: rgba(0, 0, 0, 0.1);
  min-width: 250px;
  padding-left: 5px;
  outline: none;
  transition: 0.3s;
}
.Register-Team-Form-MemberDetails input:focus-visible,
.Register-Team-Form-LeaderDetails input:focus-visible,
.Register-Team-Form-Container input:focus-visible {
  border-bottom: 2px solid #fff;
  color: #fff;
}
.Register-Team-Form button {
  display: block;
  font-size: 16px;
  margin-top: 10px;
  line-height: 14px * 2;
  padding: 10px 20px;
  background: #1ebaa3;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 25px 5px;
  box-shadow: 3px 3px 1px 1px #95a4ff, 3px 3px 1px 2px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.register-btn-status-wrapper {
  margin-left: auto;
}
.register-btn-status-wrapper a {
  display: block;
  font-size: 16px;
  margin-top: 10px;
  line-height: 14px * 2;
  padding: 10px 20px;
  background: #1ebaa3;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 25px 5px;
  box-shadow: 3px 3px 1px 1px #95a4ff, 3px 3px 1px 2px rgba(0, 0, 0, 1);
  cursor: pointer;
}

.Register-Team-Form-Container input::-webkit-outer-spin-button,
.Register-Team-Form-Container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Register-Team-Form-Container input[type="number"] {
  -moz-appearance: textfield;
}
.regsiter-status-btn {
  width: 100%;
}

.regsiter-status-btn-Link {
  display: block;
  font-size: 16px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  line-height: 14px * 2;
  padding: 10px 20px;
  background: #1ebaa3;
  letter-spacing: 2px;
  color: #000;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 25px 5px;
  box-shadow: 3px 3px 1px 1px #95a4ff, 3px 3px 1px 2px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.Register-Team-Form button:hover {
  background: #57534e;
  color: white;
  border: 1px solid rgba(0, 0, 0, 1);
}
.TeamRegister {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.TeamRegister-Heading {
  font-size: 30px;
  text-align: end;
}
.TeamRegister-Link {
  /* font-size: 16px; */
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
  color: #000;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 14px * 2;
  padding: 10px 20px;
  background: #1ebaa3;
  letter-spacing: 2px;
  transition: 0.2s all ease-in-out;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 5px 25px;
  box-shadow: 3px 3px 1px 1px #95a4ff, 3px 3px 1px 2px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.TeamRegister-Link:hover {
  background: #57534e;
  color: #fff;
}
/* .warning-text {
  text-align: center;
} */
.warning-text span {
  color: red;
}
@media screen and (max-width: 768px) {
  .Register-Team-Form {
    width: 90vw;
  }
  .TeamRegister-Heading {
    text-align: left;
  }
}
/* Check Box */
.content {
  margin-right: 15px;
}
.checkBox-Container {
  display: flex;
  justify-content: space-around;
  margin: 10px 0 0 0;
}
.clear {
  clear: both;
}

.checkBox {
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 0px 2px #fff;
}

.checkBox div {
  width: 60px;
  height: 60px;
  background-color: #fff;
  top: -52px;
  left: -52px;
  position: absolute;
  transform: rotateZ(45deg);
  z-index: 100;
}

.checkBox input[type="radio"]:checked + div {
  left: -10px;
  top: -10px;
}

.checkBox input[type="radio"] {
  position: absolute;
  left: 50px;
  visibility: hidden;
}

.transition {
  transition: 300ms ease;
}
